import * as React from "react";
import { css } from "@emotion/react";
import { withTranslation, WithTranslation } from "react-i18next";
import { InputError } from "./InputError";
import { ErrorCodes } from "main/javascripts/constants/ErrorCodes";
import { Icon } from "main/javascripts/components/icon/Icon";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  hex2rgba,
  shadeColorLog,
} from "main/javascripts/styles/base/colorStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";

export interface IProps {
  errorMessages: string[];
  errorCode?: any;
  styles?: any;
  t?: any;
}

const SubmitErrorsBlockComponent: React.FC<IProps & WithTranslation> = (
  props: IProps
): React.ReactElement | null => {
  const { errorCode, errorMessages, t } = props;
  const submitErrorLocaleKey: string =
    !errorCode || errorCode !== ErrorCodes.form
      ? "component:form.submitError"
      : "component:form.submitFormError";
  return (
    <div css={[blockStyle, props.styles]}>
      <div css={iconStyle}>
        <Icon styleKey="caution" />
      </div>
      <InputError
        errorMessage={t(submitErrorLocaleKey)}
        styles={mainErrorStyle}
      />
      {(errorMessages || []).map((errorMessage: string): JSX.Element => {
        return (
          <InputError
            key={errorMessage}
            errorMessage={errorMessage}
            styles={inputErrorStyle}
          />
        );
      })}
    </div>
  );
};

export const SubmitErrorsBlock: any = withTranslation(["component"])(
  SubmitErrorsBlockComponent
);

const blockStyle = css`
  width: 100%;
  padding: ${space.atom1_5x} ${space.atom} ${space.atom1_5x} 2.5rem;
  box-sizing: border-box;
  position: relative;
  background: ${shadeColorLog(hex2rgba(accentColor.alertColor), 0.8)};
  border-radius: ${borderRadius.normal};
`;
const mainErrorStyle = css`
  padding: 0;
  margin: 0;
  display: block;
  background: 0;
  font-size: ${fontSize.caption};
`;
const inputErrorStyle = css`
  padding: ${space.atom0_5x} 0 0;
  margin: 0;
  display: block;
  background: 0;
  font-size: ${fontSize.caption};
`;
const iconStyle = css`
  position: absolute;
  top: ${space.atom};
  left: ${space.atom};
  display: block;
  font-size: 1.5rem;
  color: ${accentColor.alertColor};
  text-align: center;
  box-sizing: border-box;
`;
