import * as React from "react";
import { css } from "@emotion/react";
import { camelize } from "humps";
import { useTranslation } from "react-i18next";
import { isObjectEmpty } from "main/javascripts/utils/ObjectUtil";
import { fontSize } from "main/javascripts/styles/base/typographyStyle";
import { accentColor } from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { FormNamespaces } from "main/javascripts/constants/FormConstants";
import { nl2br } from "main/javascripts/utils/StringUtil";

export interface IProps {
  namespace: string;
  errors?: any;
}

export const ValidationErrorsBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement | null => {
  const { namespace, errors } = props;
  if (!errors || isObjectEmpty(errors)) {
    return null;
  }
  const { t } = useTranslation(["label"]);
  // console.log(errors);
  // key(field name)からnamespaceへの変換
  const convertGroupToNamespace = (groupKey: string) => {
    const namespaces = Object.keys(FormNamespaces);
    // labelのlocaleはmanagerとcompany_xxxxで共通にしている
    const camelizeGroupKey = camelize(groupKey.replace("company_", ""));
    return namespaces.find((namespace) => {
      return (
        camelizeGroupKey.toLowerCase().indexOf(namespace.toLowerCase()) !== -1
      );
    });
  };

  const localizeKey = (key: string, groupNamespace?: string): string => {
    const currentNamespace = groupNamespace || namespace;
    let keyString: string = t(`${currentNamespace}.${camelize(key)}`);
    if (keyString.indexOf(`${currentNamespace}.${camelize(key)}`) === 0) {
      keyString = key;
    }
    return keyString;
  };

  const generateGroupKeyString = (
    key: string,
    index?: number,
    parentKeyString?: string
  ) => {
    const groupNamespace = convertGroupToNamespace(key);
    const groupKeyString = localizeKey(key, groupNamespace);
    let keyString =
      index !== undefined && !Number.isNaN(index)
        ? `${groupKeyString}${index + 1}`
        : groupKeyString;
    if (parentKeyString) {
      keyString = t("form.errors.groupFormat", {
        group: parentKeyString,
        label: keyString,
      });
    }
    return keyString;
  };

  const renderValidationErrorMessage: (
    key: string,
    error: any,
    groupKeyString?: string
  ) => JSX.Element = (
    key: string,
    error: any,
    groupKeyString?: string
  ): JSX.Element => {
    let keyString = localizeKey(key);
    if (groupKeyString) {
      keyString = t("form.errors.groupFormat", {
        group: groupKeyString,
        label: keyString,
      });
    }
    let localizedMessage;

    if (typeof error.message === "object") {
      localizedMessage = t(
        `form.errors.messages.${error.message.key}`,
        error.message.options
      );
    } else {
      localizedMessage = t(`form.errors.messages.${error.message}`);
    }

    let labeledMessage;
    if (error.type === "server") {
      if (groupKeyString) {
        labeledMessage = t("form.errors.groupFormat", {
          group: groupKeyString,
          label: nl2br(error.message),
        });
      } else {
        labeledMessage = nl2br(error.message);
      }
      console.log(labeledMessage);
    } else if (localizedMessage.indexOf("form.errors.") === 0) {
      labeledMessage = nl2br(error.message);
    } else {
      labeledMessage = t("form.errors.format", {
        label: keyString,
        message: localizedMessage,
      });
    }
    return (
      <li key={key} css={errorStyle}>
        {labeledMessage}
      </li>
    );
  };

  const renderValidationErrorObject: (
    key: string,
    errorObject: any,
    parentKeyString?: string
  ) => JSX.Element[] = (
    key: string,
    errorObject: any,
    parentKeyString?: string
  ): JSX.Element[] => {
    return Object.keys(errorObject).flatMap((errorKey: string) => {
      const error: any = errorObject[errorKey];
      if (Array.isArray(error)) {
        return error.flatMap((errorObject: any, index: number) => {
          const newParentKeyString = generateGroupKeyString(
            errorKey,
            index,
            parentKeyString
          );
          return errorObject
            ? renderValidationErrorObject(
                errorKey,
                errorObject,
                newParentKeyString
              )
            : undefined;
        });
      } else {
        return renderValidationErrorMessage(errorKey, error, parentKeyString);
      }
    });
  };

  return (
    <ul css={blockStyle}>
      {Object.keys(errors).map((key: string) => {
        const error: any = errors[key];
        if (Array.isArray(error)) {
          return error.map((errorObject: any, index: number) => {
            const parentKeyString = generateGroupKeyString(key, index);
            return errorObject
              ? renderValidationErrorObject(key, errorObject, parentKeyString)
              : undefined;
          });
        } else if (typeof error === "object" && error.type === undefined) {
          return renderValidationErrorObject(key, error);
        } else {
          return renderValidationErrorMessage(key, error);
        }
      })}
    </ul>
  );
};

const blockStyle = css`
  padding: 0 ${space.atom} ${space.atom} 1.6rem;
  margin: 0;
`;
const errorStyle = css`
  font-size: ${fontSize.caption};
  color: ${accentColor.alertColor};
`;
