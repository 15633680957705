interface IErrorCodes {
  form: string;
  expired: string;
  priceChanged: string;
  outOfStock: string;
  priceConfirmation: string;
  duplicateBooking: string;
  allFailed: string;
  automaticallyCancelled: string;
  partiallyFailed: string;
  commitError: string;
}

export const ErrorCodes: IErrorCodes = {
  form: "form",
  expired: "expired",
  priceChanged: "price_changed",
  outOfStock: "out_of_stock",
  priceConfirmation: "price_confirmation",
  duplicateBooking: "duplicate_booking",
  allFailed: "all_failed",
  automaticallyCancelled: "automatically_cancelled",
  partiallyFailed: "partially_failed",
  commitError: "commit_error",
};
